.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subtitle-wrapper {
    display: flex;
    align-items: center;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: var(--gray-600);
    margin-right: 8px;
}

.btn-consultar-tabela-base {
    background: var(--Light-Blue-5, #F5F9FE);
    color: var(--Blue-700, #0D66D0);
    font-size: 12px;
    font-weight: 400;
    height: 30px;

    &:hover, &:focus {
        background: var(--Light-Blue-5, #F5F9FE);
        color: var(--Blue-700, #0D66D0);
    }
}
