@mixin variation($color: 'black') {
    color: var(--green-500, #2d9d78);
    color: $color;

    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

@mixin default-text() {
    color: var(--gray-600, #959595);
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.container {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--light-blue-20, #d7e8fc);
    background: var(--light-blue-5, #f5f9fe);
    padding: 6px 12px;
    min-width: 160px;

    &[data-highlight='true'][data-variation='positive'] {
        border: 1.5px solid var(--green-400, #33ab84);
        background: var(--light-green-5, #f5fbf9);
    }

    &[data-highlight='true'][data-variation='negative'] {
        border: 1.5px solid var(--red-400, #d7373f);
        background: var(--light-red-5, #fef5f5);
    }

    .main {
        display: flex;
        gap: 4px;

        .value {
            color: var(--gray-900, #323232);
            font-family: 'Open Sans';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
        }

        .variation {
            display: flex;
            align-items: center;
            @include variation(var(--green-500, #2d9d78));

            [data-variation='negative'] {
                color: (#d7373f);
            }
        }
    }

    .description {
        @include default-text();
    }

    .content {
        display: flex;
        align-items: baseline;
        gap: 4px;

        :first-child {
            @include default-text();
        }

        :last-child {
            color: var(--gray-700, #747474);
            font-family: 'Open Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }
}
