@mixin border-default() {
    border-radius: 4px;
    border: 1px solid var(--gray-500, #bcbcbc);
    background: var(--White, #fff);
}

.container {
    .main-container > ul[role='menu'] {
        & > li:first-child {
            padding: 12px;
            border-bottom: 1px solid var(--gray-300, #eaeaea);
        }

        & > li:nth-child(2) {
            p {
                padding: 12px 12px 4px 12px;
            }
            :global(.rs-checkbox-checker) {
                padding-block: 12px;
            }
            :global(.rs-checkbox-wrapper::after) {
                top: -12px;
                right: -12px;
                bottom: -12px;
                left: -12px;
            }
        }
    }

    div[role='button'] {
        color: var(--Gray-900, #323232);

        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        line-height: 20px; /* 142.857% */

        > :global(.rs-radio-checked) {
            label {
                font-weight: 600;
            }
        }
    }

    .title {
        color: #000;

        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 12px;
    }
}

.operator-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .currency-input {
        max-width: 58px;
        border: none !important;
        height: 38px;
        padding-inline: 11px;

        &:focus-visible {
            outline: none;
            border: none !important;
        }
    }

    .input-group {
        @include border-default();

        &:global(.rs-input-group-focus) {
            border-color: var(--gray-500, #bcbcbc) !important;
        }

        &:hover {
            border-color: var(--gray-500, #bcbcbc) !important;
        }
    }

    :global(.rs-input-group-addon) {
        color: var(--gray-800, #505050);
        font-feature-settings:
            'liga' off,
            'clig' off;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}

.operator-content {
    width: 152px;
    display: flex;
    align-items: center;

    @include border-default();

    > span {
        width: 100%;
    }

    & > ul[role='menu'] {
        width: 152px;
    }

    .render-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;

        p {
            color: var(--gray-900, #323232);
            font-family: 'Open Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }
}
