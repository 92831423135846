.table {
    color: var(--gray-900, #323232);
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    border-radius: 6px;
    border: 1px solid var(--gray-300, #eaeaea);
    background: var(--white, #fff);
}
