.badge {
    padding: 2px 8px;
    border-radius: 999px;
    height: 20px;
    width: 22px;
    background: var(--blue-400, #378ef0);
    color: var(--white, #fff);

    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 120% */
    text-transform: uppercase;
}

.render-extra-footer {
    border-top: 1px solid var(--gray-400, #d3d3d3);
    padding: 6px 4px;
    left: 0;
}

#select-picker {
    color: var(--blue-500, #2680eb) !important;
    text-align: center;

    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */

    background: var(--light-gray, #f5f9fe) !important;
    border: none !important;

    & :global(.rs-picker-toggle-placeholder) {
        color: var(--blue-500, #2680eb) !important;
    }

    & :global(.rs-picker-toggle-caret) {
        color: var(--blue-500, #2680eb) !important;
    }

    &:hover {
        background: #f5f9fe95 !important;
    }
}

#select-picker.active {
    border: 1px solid var(--gray-300, #c0c0c0) !important;
}
