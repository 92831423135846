.const-field__input-group {
    background: white; // só pra classe abaixo ser gerada...
    :global(.rs-radio-group) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.cluster-field__input-group {
    :global(.rs-radio-group) {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
