.modal-description {
    margin-block-end: 32px;
    font-size: 14px;
    line-height: 20px;
    color: #323232;
}

.error-message {
    color: #ec5b62;
    font-size: 12px;
    margin-top: 4px;
}

.input-placeholder {
    &::placeholder {
        font-weight: 600;
        color: #BCBCBC;
        font-size: 14px;
        line-height: 20px;
    }
}
