$default-width: 156px;

.container {
    display: flex;
    flex-direction: column;
    padding: 18px 12px;
    gap: 2px;

    border-radius: 6px;
    background: var(--white, #fff);

    min-width: $default-width;

    &:first-child {
        min-width: 390px;
    }

    &:nth-child(2) {
        min-width: 206px;
    }

    .label {
        color: var(--gray-600, #959595);
        font-family: 'Open Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .content {
        color: var(--gray-900, #323232);
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
    }
}
