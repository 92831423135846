.modal-speak-with-specialist {
    width: 480px;
    :global(.rs-modal-body) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        text-align: center;
    }
    :global(.rs-modal-content) {
        background: linear-gradient(177deg, #D7E8FC 2.45%, #FFF 41.1%) !important;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    }
}

.icon-container {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
    background: #378EF0;
}

.icon {
    color: #FFF;
}

.title {
    margin: 24px 0 16px 0;
}

.subtitle {
    font-size: 14px;
}

.footer {
    display: flex;
    justify-content: center;
    gap: 16px;
}

.btn-action, .btn-cancel {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;;
}

.btn-action {
    margin: 0 !important;
}

.btn-cancel {
    border: 1px solid #378EF0 !important;
    color: #378EF0 !important;
}
