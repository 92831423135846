.container {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 8px;
    background: var(--white, #fff);

    border-radius: 6px;
    border: 1px solid var(--chart-palette-color-3, #e663c9);

    p {
        color: var(--Gray-900, #323232);

        /* Text/font-size-100-regular */
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}
