@mixin text-style($size: 10px, $weight: 400, $line-height: 14px, $color: var(--gray-800, #505050)) {
    color: $color;
    font-family: 'Open Sans';
    font-size: $size;
    font-style: normal;
    font-weight: $weight;
    line-height: $line-height;
}

.container {
    border-radius: 6px;
    background: var(--white, #fff);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    align-self: stretch;
    width: 100%;

    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;

        .title-wrapper {
            display: flex;
            flex-direction: column;

            .title {
                color: var(--gray-900, #323232);
                font-family: 'Open Sans';
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .subtitle {
                display: flex;
                align-items: center;

                p {
                    @include text-style();
                }

                button {
                    @include text-style($color: var(--Blue-400, #378ef0));
                    padding: 8px;
                }
            }
        }

        .more-options {
            :global(.rs-btn.rs-btn-icon) {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 16px;
            }
        }
    }

    .view-container {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
            color: var(--gray-900, #323232);

            font-family: 'Open Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }

        .buttons-container {
            display: flex;
            align-items: center;
            gap: 8px;

            button {
                height: 24px;
                width: 24px;
                padding: 6px;

                border-radius: 4px;

                display: flex;
                justify-content: center;
                align-items: center;

                &[data-active='false'] {
                    background: var(--light-Blue-5, #f5f9fe);
                }
            }
        }
    }
}
