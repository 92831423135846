.table-list-table-price-container {
    margin: 0 0 10px 0;
    background: #ffffff;
    border-radius: 6px;
}

.main-bold-text, .regular-text {
    font-size: 14px;
    color: #323232;
    margin: 0;
}

.main-bold-text {
    font-weight: 600;
}

.regular-text {
    font-weight: 400;
}

.sub-text {
    font-size: 11px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.table-list-table-price {
    border-radius: 6px;
}

.table-list-table-price-row {
    :global(.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.flex {
    display: flex;
    align-items: center;
    height: -webkit-fill-available;
}

.btn-row-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #eaeaea;
}

.more-icon {
    height: 16px !important;
    width: 16px !important;
    color: #959595;
}

.border-bottom {
    border-bottom: 1px solid #eaeaea;
}

.cell-action {
    :global(.rs-table-cell-content) {
        display: flex;
        justify-content: flex-end;
    }
}

.cell-center {
    :global(.rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.cell-right {
    :global(.rs-table-cell-content) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.pending-alert {
    display: flex;
    align-items: center;
    background: #FEF6E9;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 6px;
}

.alert-icon {
    width: 12px !important;
    height: 12px !important;
    color: #F29423;
    margin-right: 4px;
}

.alert-text {
    font-size: 12px;
}
