.select-pill-wrapper {
    display: flex;
}

.arrow-right-icon {
    margin: 0 15px;
}

.check-all-ufs {
    :global(.rs-checkbox-checker) {
        padding-right: 10px;
    }
}
