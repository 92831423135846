@import '../../../../../../style/abstracts/variables.scss';

.detailing-content {
    border-radius: 6px;
    margin: 0 !important;
    position: relative;
}

.detailing-title {
    font-weight: 600;
    font-size: 14px;
    color: $gray-900;
    margin-bottom: 14px;
}

.table-container {
    border: 1px solid $gray-300;
    border-radius: 6px;

    :global(.rs-flex-box-grid-item) {
        margin: 0;
    }
}

.table-header {
    padding: 10px 24px;
    font-weight: 400;
    font-size: 12px;
    color: $gray-600;
}

.table-item {
    margin: 0;
}

.panel-row {
    border-radius: 0;
    cursor: default;
    :global(.rs-panel-heading) {
        border-top: 1px solid $gray-300;
        padding: 12px 20px;

        &::before {
            display: none;
        }
    }
}

.info-icon {
    height: 12px;
    margin-left: 4px;
}
