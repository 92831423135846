.modal-container {
    :global(.rs-modal-header) {
        color: var(--gray-900, #323232);
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        padding: 24px;
    }

    .title {
        color: var(--gray-900, #323232);
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding-bottom: 12px;
    }

    :global(.rs-modal-content) {
        background: var(--white, #fff);
        padding: 0;
    }

    :global(.rs-modal-body) {
        padding: 0 24px 24px 24px;
        margin: 0;

        ul {
            color: var(--gray-900, #323232);
            font-family: 'Open Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    :global(.rs-modal-footer) {
        border-top: 1px solid #eaeaea;

        padding: 24px;

        button {
            border-radius: 4px;
            background: var(--blue-400, #378ef0);
            text-transform: uppercase;
            width: 140px;
        }
    }
}
