.container {
    :global(.rs-picker-toggle-caret) {
        display: none !important;
    }

    :global(.rs-picker-toggle-clean) {
        display: none !important;
    }

    :global(.rs-icon) {
        display: none !important;
    }

    :global(.rs-picker-toggle-clean) {
        display: none !important;
    }

    :global(.rs-picker-toggle-value) {
        color: #505050 !important;
    }
}
