.container {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 16px 16px;
    gap: 12px;
}

@media (min-width: 1200px) and (max-width: 1464px) {
    .container {
        padding-top: 4.5rem;
    }
}
