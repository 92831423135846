.container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .big-numbers-container {
        border-radius: 6px;
        background: var(--white, #fff);
        padding: 12px;

        display: flex;
        flex-direction: column;
        gap: 8px;

        .label {
            color: var(--gray-900, #323232);
            font-family: 'Open Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 150% */
        }

        .title {
            color: var(--gray-900, #323232);

            font-family: 'Open Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
            margin-bottom: 8px;
        }

        .period {
            display: flex;
            align-items: center;
            gap: 8px;

            & > div {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .date {
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 4px 12px;

                color: var(--gray-800, #505050);
                text-align: center;

                font-family: 'Open Sans';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */

                border-radius: 6px;
                background: var(--Gray-100, #f5f5f5);
            }
        }

        .big-numbers {
            display: flex;
            gap: 8px;
        }
    }

    .ia-container {
        display: flex;
        gap: 16px;
        padding: 4px 8px;

        border-radius: 6px;
        background: rgba(230, 99, 201, 0.1);

        p {
            color: var(--gray-900, #323232);
            font-family: 'Open Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
        }
    }
}
