.modal-banner-component {
    .rs-modal-header {
        button {
            color: #fff;
        }
    }

    .rs-modal-content {
        width: fit-content;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(180deg, #378EF0 0%, #0D66D0 100%);
    }

    .top-text {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
    }

    .image-area {
        margin: 26px 40px;

        img {
            width: 300px;
        }
    }

    .btn-more {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        background: var(--Orange-400, #F29423);
    }

    .container {
        background-color: transparent;
        bottom: 0;
        height: 5px;
        position: absolute;
        right: 0;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .container .progress-bar {
        animation: progress-animation 13s infinite;
        background-color: #1E88E5;
        border-radius: 0px 6px 6px 6px;
        height: 100%;
        position: absolute;
    }

    @keyframes progress-animation {
        0% {
            width: 0%;
        }

        100% {
            width: 100%
        }
    }
}
