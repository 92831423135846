.wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    padding: 24px 16px;
    border-radius: 6px;
    background-color: var(--white);
    border: 1px solid var(--gray-300);

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li:last-child {
        margin-inline-start: auto;
    }
}

.button {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 9999999px;
    padding: 0px;
    padding-left: 12px;
    color: var(--blue-500);
    background: var(--light-blue-5, #f5f9fe);

    &__content:hover {
        color: var(--blue-700);
    }

    &__icon-container {
        display: grid;
        place-content: center;
        transition: var(--base-transition);

        &:hover {
            color: var(--blue-700);
        }
    }

    &__end-icon {
        box-sizing: border-box;
        background: var(--light-blue-15, #e1eefd);
        padding: 9px 10px;
        border-radius: 9999999px;
    }
}
