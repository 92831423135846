.family-modal {
    width: 1200px;

    :global(.rs-modal-body) {
        padding: 0;
        margin-top: 0;
    }

    :global(.rs-modal-header) {
        padding: 10px;
    }

    :global(.rs-modal-content) {
        padding: 20px 0 20px 0;
    }
}

.modal-title {
    margin-bottom: 26px;
}

.header-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid #eaeaea;
    padding: 12px;
    border-radius: 6px;

    &__row {
        display: flex;
        gap: 24px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;

        p {
            font-weight: 700;
            font-size: 10px;
            line-height: 20px;
            color: var(--gray-700);
            margin: 0;
        }

        span {
            font-size: 14px;
            line-height: 20px;
            color: var(--gray-900);
            font-weight: 600;
        }
    }
}

.info {
    color: var(--gray-700, #747474) !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    font-size: 14px !important;
}
