.buttons {
    display: flex;
    gap: 8px;

    button {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        background: var(--gray-200, #f4f4f4);
        padding: 8px;
    }

    .main-button {
        width: fit-content;
    }
}
