.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.table {
    --_boder-color: var(--gray-300);
    --_boder-radius: 6px;
    --_inline-cell-padding: 6px;
    border-collapse: separate;
    text-align: left;
    text-wrap: nowrap;
    border: 1px solid var(--_boder-color);
    border-radius: var(--_boder-radius);
    min-height: var(--_min-height, auto);

    :global(.rs-divider-horizontal) {
        margin: 0px;
    }

    td {
        border-top: 1px solid var(--_boder-color);
    }

    thead {
        height: var(--table-header-height, auto);

        th {
            color: var(--gray-600, #959595);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            font-weight: 400;
            padding-inline: 24px !important;
            font-size: 12px;
        }
    }

    tbody {
        td {
            height: var(--table-row-height, auto);
            padding-inline: 24px;
        }
    }

    &__pagination {
        padding: 0px;
    }
}
