@import '../../../../../style/typography.scss';

.gerenciador-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block-end: 12px;

    :global(.rs-dropdown) {
        background: var(--gray-300);
        border-radius: 6px;
        height: 26px;
    }

    :global(.rs-dropdown-menu) {
        width: 140px;
    }

    :global(.rs-btn.rs-btn-subtle.rs-dropdown-toggle) {
        color: var(--gray-800);
        font-size: 12px;
        line-height: 18px;
        padding-block: 4px;
    }

    :global(.rs-dropdown-toggle-caret) {
        top: 4px;
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__button {
        white-space: nowrap;
    }

    &__icon {
        margin-right: 6px;
        margin-bottom: -2px;
        color: var(--gray-600);
    }

    &__action-container {
        display: flex;
        align-items: center;
        gap: 6px;
    }
}
