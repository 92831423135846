.container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 6px;
    background: var(--white, #fff);

    font-family: 'Open Sans';

    .title {
        display: flex;
        gap: 8px;

        color: var(--gray-900, #323232);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--gray-300, #eaeaea);

        .filter-selection-wrapper {
            display: flex;
            gap: 12px;
            align-items: center;

            & > span {
                color: var(--gray-900, #323232);
                min-width: 100px;

                font-size: 12px;
                font-weight: 600;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
            }

            .badge {
                padding: 2px 8px;
                border-radius: 999px;
                height: 20px;
                width: 22px;
                background: var(--blue-400, #378ef0);
                color: var(--white, #fff);

                font-family: 'Open Sans';
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: 12px; /* 120% */
                text-transform: uppercase;
            }
        }
    }
}

.render-extra-footer {
    border-top: 1px solid var(--gray-400, #d3d3d3);
    padding: 6px 4px;
    left: 0;
}

.error {
    color: var(--red-400, #ec5b62);

    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
