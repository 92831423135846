.container {
    // display: flex;
    padding: 12px;

    border-radius: 6px;
    background-color: var(--white, #fff);

    .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid var(--gray-300, #eaeaea);
        padding: 12px;
    }

    .content {
        & > div {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 8px;
            & + div {
                margin-block-start: 10px;
            }
        }
    }
}
