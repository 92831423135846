.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 6px;
    background: var(--white, #fff);
    gap: 74.5px;

    font-family: 'Open Sans';

    & h1 {
        color: var(--gray-900, #323232);

        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 130% */
    }

    .search {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 8px 12px;
        gap: 8px;

        border-radius: 6px;
        border: 1px solid var(--gray-300, #eaeaea);
        background: var(--white, #fff);

        & input {
            flex: 1;
            border: none;
            outline: none;
        }
    }

    & button {
        color: var(--white, #fff);
        text-align: center;
        font-feature-settings:
            'liga' off,
            'clig' off;

        /* Buttons/large */
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 137.5% */
        text-transform: uppercase;

        border-radius: 4px;
        background: var(--blue-400, #378ef0);
    }
}
