@mixin default-text() {
    color: var(--gray-900, #323232);
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    line-height: 20px; /* 142.857% */
}

@mixin badge-root {
    border-radius: 4px;
    padding: 0px 2px;
    font-size: 10px;
    font-style: normal;
    line-height: 12px; /* 120% */
    text-transform: uppercase;
}

.container {
    border-radius: 0.375rem;
    background: var(--white, #fff);

    .expiredRow {
        opacity: 0.5;
    }

    .main-content {
        @include default-text();

        &:nth-child(2) {
            color: #ec5b62;
        }

        .highlight {
            font-weight: 600;
        }
    }

    .column-header {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .badge-green {
        @include badge-root();
        border: 1px solid var(--green-400, #33ab84);
        background: var(--light-green-5, #f5fbf9);
        color: var(--green-400, #33ab84);
    }

    .badge-red {
        @include badge-root();
        border: 1px solid var(--Red-400, #ec5b62);
        background: var(--light-green-5, #f5fbf9);
        color: var(--red-400, #ec5b62);
    }

    .badge-gray {
        @include badge-root();
        background: var(--gray-100, #f5f5f5);
        color: var(--gray-600, #959595);
    }

    .result-projection {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .sub-content-category {
        color: var(--gray-600, #959595);

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .sub-content-projection,
    .sub-content-store {
        color: var(--gray-700, #747474);

        font-family: 'Open Sans';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 140% */
    }

    .icon-button {
        border-radius: 4px;
        background: var(--gray-200, #f4f4f4);
    }

    .inactive {
        opacity: 0.5;
    }

    .table {
        --_table-cell-content-padding: 12px;
        &:global(.rs-table-cell-content) > div {
            padding-inline: var(--_table-cell-content-padding);
        }

        .padding-inline-xxs {
            --_table-cell-content-padding: 4px;
        }
    }
}
