.container {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 16px 16px;
    gap: 12px;

    > section {
        display: flex;
        padding: 16px;
        gap: 16px;
        justify-content: flex-end;

        border-radius: 6px;
        background: var(--white, #fff);

        > button {
            color: var(--white, #fff);
            text-align: center;
            font-feature-settings:
                'liga' off,
                'clig' off;

            font-family: 'Open Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            text-transform: uppercase;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1464px) {
    .container {
        padding-top: 4.5rem;
    }
}
