.btn-left-icon {
    display: flex;
    border: none;
    border-radius: 4px;
    height: fit-content;
    width: max-content;
    padding: 0;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    background: #EBF4FE;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    background: #fff;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: fit-content;
    padding: 5px 8px;
}
