.container {
    height: 20px;
    width: 100%;
    max-width: 400px;
    .error {
        position: absolute;

        color: var(--red-400, #ec5b62);

        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}
