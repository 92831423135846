.modal-activate-product-promo {
    width: 500px;
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form {
    display: flex;
    gap: 16px;
}

.input-wrapper, .input-wrapper-date {
    :global(.rs-input-group) {
        margin-top: 4px;
    }
}

.input-wrapper-date {
    :global(.rs-picker-date) {
        :global(.rs-picker-toggle) {
            padding: 7px 11px !important;
            :global(.rs-picker-toggle-caret) {
                display: none;
            }
        }
    }
}

.calendar-icon {
    width: 14px;
    height: 14px;
}

.btn-close, .btn-cancel-offer, .btn-activate-offer {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-cancel-offer {
    border-color: #D7373F !important;
    color: #D7373F !important;
}

.currency-input {
    border: none !important;
}

.date-picker-inicio, .date-picker-termino {
    :global(.rs-picker-toggle-clean) {
        display: none;
    }
}
