.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
    text-align: center;

    img {
        width: 80px;
        height: 80px;
        margin-bottom: 24px;
    }
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

.character-input, .character-input-error  {
    border-radius: 8px;
    width: 46px;
    height: 64px;
    line-height: 60px;
}

.character-input {
    border: 1px solid #d3d3d3;
}

.character-input-error {
    border: 1px solid #EC5B62;
}

.container-input {
    width: 232px;
    height: 64px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.character-inactive-input {
    background-color: #ffffff;
}


.btn-resend-code {
    background-color: transparent;
    color: #378EF0;
    padding: 2px !important;

    &:hover, &:focus {
        background-color: transparent;
        color: #378EF0;
    }
}

.btn-confirm {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 24px;
}
