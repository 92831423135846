@import '../../../../../style/typography.scss';

.btn-apply {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-gray {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
